<template>
  <router-view></router-view>
</template>
<script>

export default {
  mounted() {
    // if (!this._isMobile()) {
    //   window.location.href = "https://www.mtmexc.com/";
    //   console.log('手机端')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}

</script>
<style lang="less">
body {
  --bg-theme: #fef3f1;
  --color-theme1: #ffa85a;
  --color-theme2: #ef4923;
  .green {
    color: #01a781;
  }
  .red {
    color: #ef4923;
  }
}

.el-divider {
  background-color: rgb(62, 63, 65) !important;
  margin: 16px 0;
}

.line-8 {
  background: black;
  height: 8px;
}

.el-message-box {
  width: 80% !important;
}

.el-table {
  background: transparent !important;

  &::before {
    height: 0 !important;
  }

  tr {
    background: transparent !important;
  }

  th.el-table__cell {
    color: #797d82 !important;
    font-size: 12px;
    background: transparent !important;
  }

  td.el-table__cell,
  th.el-table__cell.is-leaf {
    border-bottom: none !important;
    color: #FFF;
  }

  tr:hover>td.el-table__cell {
    background: transparent !important;
  }
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  padding-bottom: 30px;

  img {
    width: 100px;
    height: 100px;
  }

  p {
    color: #777;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 15px;
  }
}</style>
